import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
const CTAButton = ({ label }) => {
  return (
    <Link
      to="/contact"
      className="bg-blue-400 text-white text-base-xxl text-sm h-full font-medium flex items-center w-50 justify-center"
    >
      {label}
    </Link>
  );
};

CTAButton.propTypes = {
  label: PropTypes.string,
};

export default CTAButton;
