import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const CollapseMenu = ({
  item,
  size = 'sm',
  basePath,
  isParam,
  children,
  callback,
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <li className="flex gap-2 pt-3">
        <div>
          <Link
            to={`${basePath}/${isParam ? `?type=${item.url}` : item.url}`}
            className={`font-semibold text-${size} hover-blue-400 inline-block ${show ? 'text-blue-400' : 'text-blue-900'}`}
            onClick={() => callback && callback()}
          >
            {item.title}
          </Link>
        </div>
        <button
          className="bg-transparent border-0 flex items-start ms-auto pt-1"
          onClick={() => setShow((prev) => !prev)}
        >
          {item?.subMenu &&
            (show ? (
              <StaticImage
                src="../../images/icons/minus.svg"
                quality={100}
                width={20}
                height={20}
                placeholder="none"
                alt="Minus icon"
              />
            ) : (
              <StaticImage
                src="../../images/icons/plus.svg"
                quality={100}
                width={20}
                placeholder="none"
                height={20}
                alt="Plus icon"
              />
            ))}
        </button>
      </li>
      <div className={`${isParam ? 'w-full' : 'w-90'}`}>
        {show ? children : null}
      </div>
    </>
  );
};

CollapseMenu.propTypes = {
  basePath: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  isParam: PropTypes.bool,
  item: PropTypes.object,
  callback: PropTypes.func,
};

export default CollapseMenu;
