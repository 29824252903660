import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import NavSubMenuMobile from './NavSubMenuMobile';
import { useTranslation } from 'react-i18next';

const NavMenuMobile = ({ show, setShow, enableBodyScroll, solutions }) => {
  const { t } = useTranslation();
  const { navMenu } = t('pageHeader', { returnObjects: true });

  return (
    <nav
      className={`hidden-lg min-h-100vh h-100vh flex-1 w-100vw flex-1 bg-blue-900 px-6 pt-60-px pb-6 left-0 top-0 -z-10 ${show ? 'fixed' : 'hidden absolute'}`}
    >
      <ul className="flex flex-col gap-6 py-6 justify-center items-center">
        {navMenu.map((item) => {
          if (item.subMenu) {
            return (
              <NavSubMenuMobile
                key={item.title}
                item={item}
                basePath={item.href}
                solutions={solutions}
                callback={() => setShow(false)}
              />
            );
          }
          return (
            <li key={item.title}>
              <Link
                to={item.href}
                onClick={() => {
                  setShow(false);
                  enableBodyScroll();
                }}
                className="text-white text-sm font-medium"
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
NavMenuMobile.propTypes = {
  show: PropTypes.bool,
  enableBodyScroll: PropTypes.func,
  setShow: PropTypes.func,
  solutions: PropTypes.array,
};

export default NavMenuMobile;
