import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import CollapseMenu from './CollapseMenu';

const NavSubMenuMobile = ({ item, basePath, callback, solutions }) => {
  const [show, setShow] = useState(false);
  return (
    <li>
      <span
        to={item.href}
        onClick={() => {
          setShow((prev) => !prev);
        }}
        className="text-white text-sm font-medium flex items-center gap-1 justify-center"
      >
        {item.title}

        <StaticImage
          src="../../images/icons/arrow-down.svg"
          alt="right arrow"
        />
      </span>

      <Link to={item.href} className="text-white text-sm hidden">
        {item.title}
      </Link>

      {show && (
        <ul className="flex flex-col bg-white transition-all w-max-content px-6 py-3 pb-6 mt-4">
          {solutions?.map((solution) => {
            return (
              <CollapseMenu
                key={solution.title}
                basePath={basePath}
                item={solution}
                callback={callback}
              >
                {solution?.subMenu.map((subItem) => (
                  <CollapseMenu
                    key={subItem.title}
                    item={subItem}
                    basePath={basePath + '/' + solution.url}
                    size="xs"
                    callback={callback}
                  >
                    {subItem?.subMenu.map((subSubItem) => (
                      <CollapseMenu
                        key={subSubItem.title}
                        item={subSubItem}
                        basePath={
                          basePath + '/' + solution.url + '/' + subItem.url
                        }
                        size="xs"
                        isParam
                        callback={callback}
                      ></CollapseMenu>
                    ))}
                  </CollapseMenu>
                ))}
              </CollapseMenu>
            );
          })}
        </ul>
      )}
    </li>
  );
};
NavSubMenuMobile.propTypes = {
  item: PropTypes.object,
  basePath: PropTypes.string,
  callback: PropTypes.func,
  solutions: PropTypes.array,
};

export default NavSubMenuMobile;
