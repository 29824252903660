import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Search = () => {
  return (
    <div className="w-50">
      <div className="flex items-center justify-end me-6 max-w-full">
        {/* <input className="flex" /> */}
        <button className="outline-none bg-transparent flex items-center">
          <StaticImage
            src="../../images/icons/scope.svg"
            placeholder="none"
            width={24}
            height={24}
            layout="fixed"
            quality={100}
            alt="Scope icon"
          />
        </button>
      </div>
    </div>
  );
};

export default Search;
