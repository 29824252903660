import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ContactItem from './ContactItem';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const {
    contact: { label, phoneNumber, email },
  } = t('pageFooter', {
    returnObjects: true,
  });
  return (
    <div className="flex flex-col gap-5 w-full-mobile">
      <span className="font-bold text-lg-xxl text-base text-blue-800">
        {label}
      </span>

      <div className="flex flex-col gap-6">
        <ContactItem value={phoneNumber.value} text={phoneNumber.ctaText}>
          <StaticImage
            src="../../images/icons/phone-dark.svg"
            width={28}
            height={28}
            layout="fixed"
            className="me-2"
            quality={100}
            alt="Phone icon"
          />
        </ContactItem>
        <ContactItem value={email.value} text={email.ctaText}>
          <StaticImage
            src="../../images/icons/mail-dark.svg"
            width={28}
            height={28}
            layout="fixed"
            className="me-2"
            quality={100}
            alt="Mail icon"
          />
        </ContactItem>
      </div>
    </div>
  );
};

export default Contact;
