import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ContactItem from './ContactItem';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const { email, phoneNumber } = t('pageHeader', { returnObjects: true });
  return (
    <div className="flex justify-between w-full mb-4 mb-7-xxl">
      <Link to="/">
        <StaticImage
          src="../../images/site-logo.svg"
          placeholder="none"
          alt="Luxor website"
          objectFit="contain"
          width={173}
          height={78}
          quality={100}
          layout="fixed"
        />
      </Link>
      <div className="flex items-center">
        <ContactItem value={phoneNumber.value} text={phoneNumber.ctaText}>
          <StaticImage
            placeholder="none"
            src="../../images/icons/phone.svg"
            width={28}
            height={28}
            layout="fixed"
            className="me-2"
            quality={100}
            alt="Phone icon"
          />
        </ContactItem>
        <div className="w-1-px h-28-px bg-gray-300 mx-10" />
        <ContactItem value={email.value} text={email.ctaText}>
          <StaticImage
            src="../../images/icons/mail.svg"
            placeholder="none"
            width={28}
            height={28}
            layout="fixed"
            className="me-2"
            quality={100}
            alt="Mail icon"
          />
        </ContactItem>
      </div>
    </div>
  );
};

export default Contact;
