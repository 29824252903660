import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import CollapseMenu from './CollapseMenu';
import { StaticImage } from 'gatsby-plugin-image';

const NavSubMenu = ({ item, basePath, solutions }) => {
  const [show, setShow] = useState(false);
  return (
    <li
      className="h-full items-center flex relative"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      <span className="text-white text-sm flex items-center gap-1">
        {item.title}
        <StaticImage
          src="../../images/icons/arrow-down.svg"
          placeholder="none"
          alt="arrow icon"
        />
      </span>
      <Link to={item.href} className="text-white text-sm hidden">
        {item.title}
      </Link>
      {show && (
        <ul className="flex flex-col bg-white absolute left-0 top-100 transition-all w-max-content px-6 py-3 pb-6">
          {solutions?.map((solution) => {
            return (
              <CollapseMenu
                key={solution.url}
                basePath={basePath}
                item={solution}
              >
                {solution?.subMenu?.map((subItem) => {
                  return (
                    <CollapseMenu
                      key={subItem.url}
                      item={subItem}
                      basePath={basePath + '/' + solution.url}
                      size="xs"
                    >
                      {subItem?.subMenu?.map((subSubItem) => (
                        <CollapseMenu
                          key={subSubItem.url}
                          item={subSubItem}
                          basePath={
                            basePath + '/' + solution.url + '/' + subItem.url
                          }
                          size="xs"
                          isParam
                        />
                      ))}
                    </CollapseMenu>
                  );
                })}
              </CollapseMenu>
            );
          })}
        </ul>
      )}
    </li>
  );
};

NavSubMenu.propTypes = {
  basePath: PropTypes.string,
  item: PropTypes.object,
  solutions: PropTypes.array,
};

export default NavSubMenu;
