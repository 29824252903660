import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const SocialLinks = () => {
  const { t } = useTranslation();
  const {
    socialLinks: { fb, ig, in: linkedIn },
  } = t('pageFooter', { returnObjects: true });
  return (
    <div className="flex flex-col gap-5 pe-4 w-full-mobile">
      <a
        rel="noreferrer"
        href={fb.link}
        className="flex items-center text-blue-800 font-medium text-sm"
        target="_blank"
      >
        <StaticImage
          quality={100}
          src="../../images/icons/fb.svg"
          className="me-2"
          width={28}
          height={28}
          alt="Facebook icon"
        />
        {fb.label}
      </a>
      <a
        rel="noreferrer"
        href={ig.link}
        className="flex items-center text-blue-800 font-medium text-sm"
        target="_blank"
      >
        <StaticImage
          quality={100}
          src="../../images/icons/ig.svg"
          className="me-2"
          width={28}
          height={28}
          alt="Instagram icon"
        />
        {ig.label}
      </a>
      <a
        rel="noreferrer"
        href={linkedIn.link}
        className="flex items-center text-blue-800 font-medium text-sm"
        target="_blank"
      >
        <StaticImage
          quality={100}
          src="../../images/icons/in.svg"
          className="me-2"
          width={28}
          height={28}
          alt="Linkedin icon"
        />
        {linkedIn.label}
      </a>
    </div>
  );
};

export default SocialLinks;
