import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import QuickLinks from './QuickLinks';
import Contact from './Contact';
import SocialLinks from './SocialLinks';
import { useTranslation } from 'react-i18next';

const PageFooter = () => {
  const { t } = useTranslation();
  const { companyDescription } = t('pageFooter', { returnObjects: true });
  return (
    <div className="w-90-lg w-full mx-auto">
      <footer className="container mx-auto flex relative py-12 px-6">
        <div className="flex flex-wrap items-start justify-between gap-6">
          <div className="flex flex-col w-20-lg">
            <Link to="/">
              <StaticImage
                src="../../images/site-logo.svg"
                alt="Luxor website"
                objectFit="contain"
                width={173}
                height={78}
                quality={100}
                layout="fixed"
                className="mb-6"
              />
            </Link>
            <p className="text-blue-800 font-light text-base-xxl text-sm">
              {companyDescription}
            </p>
          </div>
          <QuickLinks />
          <Contact />
          <SocialLinks />
        </div>
      </footer>
    </div>
  );
};

export default PageFooter;
