import React from 'react';
import PageHeader from '../PageHeader';
import PropTypes from 'prop-types';
import PageFooter from '../PageFooter';

const PageLayout = ({ children, solutions }) => {
  return (
    <div
      id="layout"
      className="flex flex-col justify-between relative w-full h-full relative min-h-100vh"
    >
      <PageHeader solutions={solutions} />
      {children}
      <PageFooter />
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  solutions: PropTypes.array,
};

export default PageLayout;
