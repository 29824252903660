import React from 'react';
import PropTypes from 'prop-types';

const ContactItem = ({ children, value, text }) => {
  return (
    <div className="flex">
      {children}
      <div className="flex flex-col">
        <span className="font-medium text-white text-lg-xxl text-base">
          {value}
        </span>
        <span className="font-light text-white text-sm">{text}</span>
      </div>
    </div>
  );
};

ContactItem.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  text: PropTypes.string,
};

export default ContactItem;
