import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NavSubMenu from './NavSubMenu';
import { useTranslation } from 'react-i18next';

const NavMenu = ({ solutions }) => {
  const { t } = useTranslation();
  const { navMenu } = t('pageHeader', { returnObjects: true });

  return (
    <nav className="flex-1 h-full">
      <ul className="flex items-center justify-around h-full gap-6">
        {navMenu.map((item) => {
          if (item.subMenu) {
            return (
              <NavSubMenu
                key={item.title}
                item={item}
                solutions={solutions}
                basePath={item.href}
              />
            );
          }
          return (
            <li key={item.title} className="h-full items-center flex">
              <Link to={item.href} className="text-white text-sm">
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

NavMenu.propTypes = {
  solutions: PropTypes.array,
};

export default NavMenu;
