import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const QuickLinks = () => {
  const { t } = useTranslation();
  const { quickLinks } = t('pageFooter', { returnObjects: true });
  return (
    <div className="flex flex-col gap-6 w-full-mobile">
      <span className="font-bold text-lg-xxl text-base text-blue-800">
        Quick links
      </span>
      {quickLinks.map((link) => (
        <Link
          key={link.label}
          to={link.url}
          className="text-lg-xxl text-base text-blue-800"
        >
          {link.label}
        </Link>
      ))}
    </div>
  );
};

export default QuickLinks;
