import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Contact from './Contact';
import NavMenu from './NavMenu';
import Search from './Search';
import CTAButton from '../Buttons/CTAButton';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import NavMenuMobile from './NavMenuMobile';
import { useTranslation } from 'react-i18next';
import { isBrowser } from '../Helpers';

const PageHeader = ({ solutions }) => {
  const { t } = useTranslation();
  const { contact } = t('pageHeader', { returnObjects: true });
  const [show, setShow] = useState(false);

  const disableBodyScroll = useCallback(() => {
    if (isBrowser) {
      /*eslint no-undef: "warn"*/
      document.body.style.overflowY = 'hidden';
    }
  }, []);

  const enableBodyScroll = useCallback(() => {
    if (isBrowser) {
      /*eslint no-undef: "warn"*/
      document.body.style.overflowY = 'auto';
    }
  }, []);

  useEffect(() => {
    if (show) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [show, enableBodyScroll, disableBodyScroll]);

  return (
    <header className="w-full">
      <div className="absolute top-0 left-50 -translate-x-50 pt-6 pt-9-xl w-full max-w-1536 z-10 w-90-lg hidden inline-block-lg">
        <div className="px-6">
          <Contact />
          <div className="ps-6 bg-gray-900-70 flex items-center h-76-px-xxl h-60-px">
            <NavMenu solutions={solutions} />
            <div className="flex items-center h-full w-40">
              <Search />
              <CTAButton label={contact} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`hidden-lg inline-block z-9999 ${show ? 'fixed' : 'absolute'} w-100vw h-60-px left-0 top-0 bg-blue-900 px-6 z-10 relative`}
      >
        <div className="flex items-center justify-between h-full">
          <Link to="/">
            <StaticImage
              src="../../images/site-logo.svg"
              placeholder="none"
              alt="Luxor website"
              objectFit="contain"
              height={30}
              quality={100}
              layout="fixed"
            />
          </Link>

          <button
            type="button"
            className="bg-transparent border-0 outline-none h-28-px w-28-px relative"
            onClick={() => setShow((prev) => !prev)}
          >
            <StaticImage
              quality={100}
              placeholder="none"
              src="../../images/icons/close.svg"
              layout="fixed"
              width={28}
              height={28}
              className={`${show ? 'scale-in' : 'scale-out'} absolute left-0 top-0`}
              alt="Close icon"
            />
            <StaticImage
              quality={100}
              placeholder="none"
              src="../../images/icons/hamburger-menu.svg"
              layout="fixed"
              width={28}
              height={28}
              className={`${show ? 'scale-out' : 'scale-in'} absolute left-0 top-0`}
              alt="Menu icon"
            />
          </button>
        </div>
        <NavMenuMobile
          show={show}
          setShow={setShow}
          enableBodyScroll={enableBodyScroll}
          solutions={solutions}
        />
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  solutions: PropTypes.array,
};

export default PageHeader;
